import {getLogger} from '../helper/consola/logger.client';

export default defineNuxtPlugin(async (nuxtApp) => {
    const event = useRequestEvent();
    const runtimeConfig = useRuntimeConfig();
    const logger = await getLogger(runtimeConfig, event);
    const sendError = (err: any, target: any = null) => {
        if (process.server) return;
        try {
            JSON.stringify(target);
        } catch (e) {
            target = null
        }
        logger.error(err, target);
    }
    nuxtApp.hook('page:finish', (pageComponent) => {
        if (process.server) return;
        logger.log('Accessed ' + pageComponent)
    });

    nuxtApp.hook('app:error', sendError);

    nuxtApp.hook('vue:error', sendError);
    return {
        provide: {
            logger
        }
    }
})
