const MINI_PHONE = 355
const MEDIUM_PHONE = 449
const PHONE = 767
const UPPER_PHONE = 950
const TABLET = 1023
const DESKTOP = 1140
const LARGE_DESKTOP = 1440

const TIME_NOTIFY_LOGIN = 600000;
const TIME_NOTIFY_BOOKABLE = 300000;

// const ASSETS_CDN =  process.env.VUE_APP_ENV === 'local' ? '/assets' : `${process.env.VUE_APP_STATIC_CDN}/static`

const TRAVELLER_DEFAULT_PHONE = '+61 3 9071 0069';
const TA_DEFAULT_PHONE = '+6139 0710 053';
const UK_DEFAULT_PHONE = '+44 1483 968 737';
const US_DEFAULT_PHONE = '(210) 791-6668';
const US_CONTACT_PHONE = '(323) 844-8198';
const AU_DEFAULT_PHONE = '1800 960 693';

const TRIP_NOT_FOUND = 'Trip Not Found'

const SEARCH_TAB = [
  {
    id: 1,
    label: 'Trip Plans',
    class: 'view_trip_plan_tab'
  },
  {
    id: 3,
    label: 'Travel Insights',
    class: 'view_travel_guide_tab'
  },
  {
    id: 2,
    label: 'Local Designers',
    class: 'view_local_designer_tab'
  }
]

const ERROR_MESSAGE_PHONE = [
  'This phone number does not seem to exist.',
  'This phone number is too long.',
  'This phone number is too short.'
]

const DEFINE_META_TAG = [
  {
    'http-equiv': 'X-UA-Compatible',
    content: 'IE=edge',
  },
  {
    name: 'viewport',
    content: 'width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=0',
  },
  {
    name: 'facebook-domain-verification',
    content: '570amz1gh729ix2r0lrb0y7drzl424',
  },
  {
    name: 'apple-mobile-web-app-status-bar-style',
    content: 'default',
  },
  {
    name: 'msapplication-TileImage',
    content: 'https://s3-cdn.designerjourneys.com/assets/vprod-2.47.4.3/img/icons/msapplication-icon-144x144.png',
  },
  {
    name: 'msapplication-TileColor',
    content: '#000000',
  },
  {
    name: 'apple-mobile-web-app-capable',
    content: 'yes',
  },
  {
    name: 'apple-mobile-web-app-title',
    content: 'Designer Journeys',
  },
  {
    name: 'application-name',
    content: 'Designer Journeys',
  },
  {
    name: 'msapplication-config',
    content: 'none',
  },
]

const SCHOOL_TOUR_ROUTE = {
  url: 'school-tour-details',
  name: 'school-tour-details'
}

export {
  MINI_PHONE,
  MEDIUM_PHONE,
  PHONE,
  UPPER_PHONE,
  TABLET,
  DESKTOP,
  LARGE_DESKTOP,
  TIME_NOTIFY_LOGIN,
  TIME_NOTIFY_BOOKABLE,
  /*ASSETS_CDN,*/
  TRAVELLER_DEFAULT_PHONE,
  TA_DEFAULT_PHONE,
  UK_DEFAULT_PHONE,
  US_DEFAULT_PHONE,
  US_CONTACT_PHONE,
  AU_DEFAULT_PHONE,
  TRIP_NOT_FOUND,
  SEARCH_TAB,
  ERROR_MESSAGE_PHONE,
  DEFINE_META_TAG,
  SCHOOL_TOUR_ROUTE
}
