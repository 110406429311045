<script setup>
import topMenuApi from '~/composables/api/menuApi'
import {computed, nextTick, onMounted, ref, watch} from 'vue'
import { heapScript } from '~/mixins/heap'
import AppLoading from '~/components/globals/Loading/AppLoading.vue'
import travelExplorerURL from '~/composables/api/currenciesApi'
import { useGetCookie } from '~/composables/common/useCustomCookie'
import { STATIC_CURRENCIES } from '~/enum/currency.enum'
import { TfChat } from 'tf-chat'
import enquiryApi from '~/composables/api/enquiryApi'
import currenciesApi from '~/composables/api/currenciesApi'
import { gtmScript, gtmScriptHead } from '~/mixins/gtm'
import { getOperationNumber } from './helper'
// import { errorLogger } from '~/mixins/loggerHandle'
import { DESKTOP, TABLET, PHONE } from './constant/common'
import {useCurrenciesStore} from '~/store/pinia/currencies'
import { useAuthStore } from '~/store/pinia/auth'
import { useGeneralInfoStore } from '~/store/pinia/generalInfo'
import { useHeapStore } from '~/store/pinia/heap'

const currencyLoaded = ref(false)
const {isMobile, isTablet} = useDevice()
const currenciesStore = useCurrenciesStore()
const authStore = useAuthStore()
const generalStore = useGeneralInfoStore()
const heapStore = useHeapStore()

const detectCountryData = useState('detectCountryData')

// const clientRoute = null
// const clientRoute = computed(() => {
//   return store.state.generalStore.clientRoute
// })

const getTopMenu = async () => {
  try {
    const resp = process.client && window.menu_items
        ? window.menu_items
        : (process.server && process.MENU_ITEMS) ? process.MENU_ITEMS : (await topMenuApi.getTopMenu()).data.value?.data
    generalStore.setTopMenu(resp || [])
  } catch (e) {
    console.log(e.message)
  }
}

const heapAdd = (UID) => {
  let heap_id = useAppConfig().heapID_TV
  if (window.Cookiebot?.consent?.statistics) {
    heapScript(UID, heap_id, 'heap_script')
  }
}

const amplitudeAdd = (id) => {
    if (id && window.amplitude) {
      id = userType.value + id
      window.amplitude.setUserId(id);
    }
}
const handleGTM = () => {
  let gtm_id = useAppConfig().gmtID_TV
  if (gtm_id) {
    gtmScript(gtm_id)
    gtmScriptHead(gtm_id)
  }
}

const userInfo = computed(() => {
  const user = authStore.getUserInfo
  return user
})
const timezone = computed(() => userInfo.value?.timezone)
const userId = computed(() => userInfo.value.userId)
const userType = computed(() => userInfo.value.userType)

useHead({
  htmlAttrs: {
    id: 'html',
  },
})

watch(userId,
  (newValue) => {
    if (newValue) {
      heapAdd(newValue)
      amplitudeAdd(newValue)
    }
  },
)

const phone = computed(() => {
  return generalStore.detectCountryInfo.phone
})

const detectCountryInfo = computed(() => {
  return generalStore.detectCountryInfo
})

const currencySelected = computed(() => {
  // return store.state.currenciesStore.currencySelected
  return currenciesStore.currencySelected
})

watch(
  () => generalStore.callDetect,
  () => {
    generalStore.setCallDetect(false)
    getCountryCurrencyCode()
  },
)

// get currency code and country code
const getCountryCurrencyCode = async () => {
  let resData
  const dj_curr = useGetCookie('dj_curr')
  const dj_curr_symbol = useGetCookie('dj_curr_symbol')
  const country_code = useGetCookie('country_code')
  const country_name = useGetCookie('country_name')
  const region_name = useGetCookie('region_name')
  const city_name = useGetCookie('city_name')
  const continent = useGetCookie('continent')
  const continent_code = useGetCookie('continent_code')
  const timezone = useGetCookie('timezone')
  const event = useRequestEvent()
  //console.log('========event========', event?.context)
  if (!dj_curr.value && event?.context?.currency_code) {
    dj_curr.value = event?.context?.currency_code
  }

  if (!country_code.value && event?.context?.country_code) {
    country_code.value = event?.context?.country_code
  }

  if (!continent_code.value && event?.context?.continent_code) {
    continent_code.value = event?.context?.continent_code
  }

  if (!timezone.value && event?.context?.timezone) {
    timezone.value = event?.context?.timezone
  }

  if (!region_name.value && event?.context?.region_name) {
    region_name.value = event?.context?.region_name
  }

  if (country_code.value && dj_curr.value && continent_code.value && region_name.value && timezone.value) {
    resData = detectCountryInfo.value || {}
  } else {
    let res = (await travelExplorerURL.getDetectCountry(event?.context?.ip_address)).data.value
    resData = res?.data || {}
    detectCountryData.value = resData

    country_code.value = resData.country_code
    country_name.value = resData.country_name
    region_name.value = resData.region
    city_name.value = resData.city
    continent.value = resData.continent
    continent_code.value = resData.continent_code
    timezone.value = resData.timezone
  }

  if (resData) {
    let supportedCurrency
    if (dj_curr.value) {
      supportedCurrency = STATIC_CURRENCIES.find((item) => item.code === dj_curr.value)
    } else {
      supportedCurrency = STATIC_CURRENCIES.find((item) => item.code === resData.currency_code)
    }

    dj_curr.value = supportedCurrency ? supportedCurrency.code : 'AUD'
    dj_curr_symbol.value = supportedCurrency ? supportedCurrency.symbol : 'AU$'
    resData.currency_code = dj_curr.value
    resData.currency_symbol = dj_curr_symbol.value
    resData.country_code = country_code.value
    resData.country_name = country_name.value
    resData.region = region_name.value
    resData.city = city_name.value
    resData.continent = continent.value
    resData.continent_code = continent_code.value
    resData.timezone = timezone.value
    const countryCodeToCheck = country_code.value
    let phone = getOperationNumber(countryCodeToCheck, continent_code.value)
    resData.phone = phone
    // console.log('[setDetectCountryInfo getCountryCurrencyCode]',phone)
    
    currenciesStore.setCurrencySelected(supportedCurrency)
    
    generalStore.setDetectCountryInfo(resData)
    generalStore.setDetectCountryLoaded(true)
  }
}

const getCurrenciesList = async () => {
  try {
    const dj_curr = useGetCookie('dj_curr')
    
    currenciesStore.setLoaded(false)
    
    let resData = []
    const res = process.client && window.exchange_rates
        ? window.exchange_rates
        : (process.server && process.EXCHANGE_RATES) ? process.EXCHANGE_RATES : (await currenciesApi.getCurrencies()).data.value?.data
    resData = res || []

    // const currencyDetect = store.state?.currenciesStore?.currencySelected?.code || dj_curr.value || 'AUD'
    const currencyDetect = currenciesStore.currencySelected?.code || dj_curr.value || 'AUD'

    const selected = resData.find(({ code }) => code === currencyDetect)
    
    currenciesStore.setCurrenciesList(resData)
    currenciesStore.setCurrencySelected(selected)
    currenciesStore.setLoaded(true)
    
    currencyLoaded.value = true
  } catch (e) {
    console.log(e)
  }
}

onMounted(async () => {
  await nextTick()
  handleGTM()
  if (!window.IS_BOT && !useAppConfig().trackingHeap) {
    heapAdd(userId.value)
  }
  /*if (!window.IS_BOT && !useAppConfig().trackingAmplitude) {
      amplitudeAdd(userId.value)
      //add session replay plugin to amplitude
      window.amplitude.add(window.sessionReplay.plugin({sampleRate: 1}));
  }*/


  windowWidth()
  window.addEventListener('resize', windowWidth)
  window.addEventListener('load', windowWidth)

  if (!window.IS_BOT && !useAppConfig().trackingAmplitude) {
    amplitudeAdd(userId.value)
    //add session replay plugin to amplitude
    if(window.amplitude){
      window.amplitude.add(window.sessionReplay.plugin({sampleRate: 1}));
    }
  }

  window.addEventListener('CookiebotOnConsentReady', () => {
    // try{
    //   window.amplitude.setOptOut(false);
    //   window.amplitude.track(`View Page ${route.name}`); // event type: Page View, no properties, no options
    //   //console.log('CookiebotOnConsentReady>> amplitude.setOptOut(null)');
    //   // TODO: call tracking current page after cookiebot is ready
    //
    // }catch(e){}
    heapAdd(userId.value)
    //amplitudeAdd(userId.value)
  }, false)

})

const onViewEnquiryDetails = async (enquiryId) => {
  try {
    const res = await enquiryApi.enquiryDetail(enquiryId)

    if (res.status === 200) {
      let data = {
        showModalEnquiryDetail: true,
        enquiryDetailList: res.data.data,
      }

      generalStore.setEnquiryDetail(data)
    }
  } catch (err) {
    console.log(err)
  }
}

const onChatIsReady = (value) => {
  generalStore.setFinishedChat(value)
}

const chatBoxOpenDone = (value) => {
  // console.log("[app] -> setChatBoxOpenDone", value)
  generalStore.setChatBoxOpenDone(value)
}

const heapTrackingChatError = (e_info) => {
  // console.log('heapTrackingChatError', e_info)
  throw new Error('Chat error: ', { cause: e_info })
}
const heapTrackingChat = async (value) => {
  await heapStore.heapTrackingChatWindow(value)
}
const heapTrackingChatMinimize = async () => {
  await heapStore.heapTrackingMinimizeChat()
}

const heapTrackingStatus = async (value) => {
  await heapStore.setTrackChatWindow(value)
}

const heapTrackingViewListChat = async (value) => {
  await heapStore.heapTrackingViewListChat(value)
}

const heapTrackingPhone = async () => {
  await heapStore.heapTrackingMakePhoneCall('chat-window')
}
const windowWidth = () => {
  generalStore.setWindowWidth()
}
const detectDeviceSSR = () => {
  let width = DESKTOP
  if (isTablet) {
    width = TABLET
  } else if(isMobile) {
    width = PHONE
  }
  return generalStore.setWindowWidthSSR(width + 1)
}

const appConfig = useAppConfig()
// const chatSdkJS = appConfig.chatSdkJSUri
// const chatServerUri = appConfig.chatServerUri
// const chatConnAttempts = appConfig.chatConnAttempts
// const publicApiEndpoint = appConfig.publicApiEndpoint
// const fileServiceUri = appConfig.fileServiceUri
// const fileServiceUser = appConfig.fileServiceUser
// const fileServicePasswd = appConfig.fileServicePasswd

const chatSdkConfig = {
  chatSdkJS : appConfig.chatSdkJSUri,
  chatServerUri : appConfig.chatServerUri,
  tfApiEndpoint : appConfig.publicApiEndpoint,
  fileServiceUri : appConfig.fileServiceUri,
  fileServiceUser : appConfig.fileServiceUser,
  fileServicePasswd : appConfig.fileServicePasswd,
  connectionAttempts : appConfig.chatConnAttempts || 3
}

const customClassChat = computed(() => {
  return generalStore.customClassChat
})

const clientWindowWidth = computed(() => generalStore.generalData.windowWidth)

// ---Created---
// set Currency code and country code to cookie
detectDeviceSSR()
await getCountryCurrencyCode()
await Promise.all([
  getCurrenciesList(),
  getTopMenu(),
])
</script>
<template>
  <div>
    <client-only>
      <Teleport to="body">
        <div id="bubble-popup-chat-tf" style="display: none">
          <div id="bubble-popup-overlay"></div>
          <div id="bubble-popup-wrapper">
            <div id="header-chat-tf"></div>
            <div id="content-chat-tf"></div>
          </div>
        </div>
      </Teleport>
      <Teleport to="head">
        <component :is="'script'" type="application/ld+json">
          {
          "@context": "https://schema.org",
          "@type": "TravelAgency",
          "url": "https://www.designerjourneys.com",
          "name": "Designer Journeys",
          "image": "https://s3-cdn.designerjourneys.com/media/designer-journeys-logo-dark.png",
          "address": {
          "@type": "PostalAddress",
          "streetAddress": "4/12 Elizabeth Street",
          "addressLocality": "Hawthorn",
          "addressRegion": "VIC",
          "postalCode": "3122",
          "addressCountry": "Australia"
          },
          "telephone": "+61 3 9071 0069",
          "priceRange": "$1,000 - $10,000"
          }
        </component>
        <component :is="'script'" type="application/ld+json">
          {
          "@context": "https://schema.org",
          "@type": "Organization",
          "url": "https://www.designerjourneys.com",
          "name": "Designer Journeys",
          "sameAs": [
          "https://www.facebook.com/DesignerJourneys",
          "https://www.instagram.com/designerjourneys_/",
          "https://www.pinterest.com.au/designerjourneys/",
          "https://www.linkedin.com/company/designerjourneys/"
          ],
          "logo": "https://s3-cdn.designerjourneys.com/media/designer-journeys-logo-dark.png"
          }
        </component>
      </Teleport>
      <tf-chat
        @viewEnquiryDetails="onViewEnquiryDetails"
        @chatIsReady="onChatIsReady"
        @heapTracking="heapTrackingChat"
        @heapTrackingChatError="heapTrackingChatError"
        @heapTrackingStatus="heapTrackingStatus"
        @heapTrackingMinimize="heapTrackingChatMinimize"
        @heapTrackingPhone="heapTrackingPhone"
        @heapTrackingViewListChat="heapTrackingViewListChat"
        :timezone="timezone"
        :phone="phone"
        clientRoute=""
        :customClassChat='customClassChat'
        :chatSdkConfig='chatSdkConfig'
        :clientWindowWidth='clientWindowWidth'
        @chatBoxOpenDone='chatBoxOpenDone'
      />
    </client-only>
    <AppLoading/>
    <NuxtPage/>
  </div>
</template>
<style lang="scss">
@import 'assets/scss/src/normalize';
@import 'assets/scss/styles';

.page-enter-active,
.page-leave-active {
  transition: opacity 0.1s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
}

body[data-mshow='true'] {
  overflow-y: hidden;
  height: 100vh;
}
a#CybotCookiebotDialogPoweredbyCybot {
  display: none;
}
#CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogBodyEdgeMoreDetails a {
  color: #1b1b1b !important;
}
#CybotCookiebotDialogBodyContentText {
  line-height: 1.6;
}
#CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
  line-height: normal;
}
</style>
